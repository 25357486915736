import awsmobile from '../_aws-exports-QA';

export const awsConf = {
  CognitoRegion: awsmobile.aws_cognito_region,
  UserPoolId: awsmobile.aws_user_pools_id,
  ClientId: awsmobile.aws_user_pools_web_client_id,
  IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
  OauthDetails: awsmobile.oauth,
};


export const GRAPHQL_ENDPOINT = awsmobile.aws_appsync_graphqlEndpoint;


export const USERS_ENDPOINT = awsmobile.aws_cloud_logic_custom[0].endpoint + '/users';



const NHB_LOGO = `
 _____ _____ _____
|   | |  |  | __  |
| | | |     | __ -|
|_|___|__|__|_____|
`;

const AWS_INFO = `
----- AWS INFO -------------------
user pool id  : ${awsConf.UserPoolId}
client id     : ${awsConf.ClientId}
gql endpoint  : ${GRAPHQL_ENDPOINT}
users endpoint: ${USERS_ENDPOINT}
----------------------------------
`;

// all of the REACT_APP_ vars get set by the bitbucket pipeline
const BUILD_INFO = `
----- BUILD INFO -----------------
branch        : ${process.env.REACT_APP_BRANCH}
commit        : ${process.env.REACT_APP_COMMIT}
build number  : ${process.env.REACT_APP_BUILD_NUMBER}
----------------------------------
`;

console.log(
  NHB_LOGO,
  AWS_INFO
);
